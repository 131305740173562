var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "Join base-height" }, [
      _c("div", { staticClass: "login_left" }),
      _c("div", { staticClass: "mainBox" }, [
        _vm._m(0),
        _c("div", { staticClass: "form join" }, [
          _c("h2", [_vm._v(_vm._s(_vm.$t("join_title")))]),
          _c("fieldset", [
            _c("legend", [_vm._v("정보입력")]),
            _c("div", { staticClass: "cover bg" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.username,
                    expression: "username"
                  }
                ],
                attrs: { type: "text", placeholder: _vm.$t("username") },
                domProps: { value: _vm.username },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.username = $event.target.value
                  }
                }
              })
            ]),
            _c("div", { staticClass: "cover bg" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.email,
                    expression: "email"
                  }
                ],
                attrs: { type: "text", placeholder: _vm.$t("email") },
                domProps: { value: _vm.email },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.email = $event.target.value
                  }
                }
              })
            ]),
            _c("div", { staticClass: "cover bg" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.mobileNo,
                    expression: "mobileNo"
                  }
                ],
                attrs: {
                  type: "text",
                  placeholder: _vm.$t("cellPhone"),
                  disabled: _vm.pwdEditFlag
                },
                domProps: { value: _vm.mobileNo },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.mobileNo = $event.target.value
                  }
                }
              }),
              _c("button", { on: { click: _vm.requestCertNumber } }, [
                _vm._v(_vm._s(_vm.$t("send")))
              ])
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.veyfyCodeVisible,
                    expression: "veyfyCodeVisible"
                  }
                ],
                staticClass: "cover bg"
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.certNumber,
                      expression: "certNumber"
                    }
                  ],
                  attrs: { type: "text" },
                  domProps: { value: _vm.certNumber },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.certNumber = $event.target.value
                    }
                  }
                }),
                _c("button", { on: { click: _vm.verifyNumber } }, [
                  _vm._v(_vm._s(_vm.$t("confirm")))
                ])
              ]
            ),
            _c("div", { staticClass: "cover bg" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.password,
                    expression: "password"
                  }
                ],
                attrs: {
                  type: "password",
                  min: "6",
                  placeholder: _vm.$t("joinPwd")
                },
                domProps: { value: _vm.password },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.password = $event.target.value
                  }
                }
              })
            ]),
            _c("div", { staticClass: "cover bg" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.passwordConfirm,
                    expression: "passwordConfirm"
                  }
                ],
                attrs: { type: "password", placeholder: _vm.$t("checkPwd") },
                domProps: { value: _vm.passwordConfirm },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.passwordConfirm = $event.target.value
                  }
                }
              })
            ]),
            _c("p", [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.termOfUseFlag,
                      expression: "termOfUseFlag"
                    }
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.termOfUseFlag)
                      ? _vm._i(_vm.termOfUseFlag, null) > -1
                      : _vm.termOfUseFlag
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.termOfUseFlag,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.termOfUseFlag = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.termOfUseFlag = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.termOfUseFlag = $$c
                      }
                    }
                  }
                }),
                _c("a", { attrs: { href: "#" } }, [
                  _c(
                    "b",
                    {
                      staticClass: "link",
                      on: {
                        click: function($event) {
                          return _vm.showAuth(1)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("joinTerms")))]
                  )
                ])
              ])
            ]),
            _c("p", [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.personDataCollectAgreeFlag,
                      expression: "personDataCollectAgreeFlag"
                    }
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.personDataCollectAgreeFlag)
                      ? _vm._i(_vm.personDataCollectAgreeFlag, null) > -1
                      : _vm.personDataCollectAgreeFlag
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.personDataCollectAgreeFlag,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            (_vm.personDataCollectAgreeFlag = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.personDataCollectAgreeFlag = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.personDataCollectAgreeFlag = $$c
                      }
                    }
                  }
                }),
                _c("a", { attrs: { href: "#" } }, [
                  _c(
                    "b",
                    {
                      staticClass: "link",
                      on: {
                        click: function($event) {
                          return _vm.showAuth(2)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("joinPrivacy")))]
                  )
                ])
              ])
            ]),
            _c(
              "button",
              {
                staticClass: "btn full primary",
                on: {
                  click: function($event) {
                    return _vm.submit()
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("join")) + " ")]
            )
          ])
        ])
      ])
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.visible,
            expression: "visible"
          }
        ],
        staticClass: "modal"
      },
      [
        _c("div", { staticClass: "modal_cover" }),
        _c("div", { staticClass: "modal_content" }, [
          _c("div", { staticClass: "m_header" }, [
            _c("div", [_vm._v(_vm._s(_vm.modalTitle))]),
            _c(
              "button",
              {
                staticClass: "close",
                on: {
                  click: function($event) {
                    return _vm.closeModal()
                  }
                }
              },
              [
                _c("span", { staticClass: "material-icons" }, [
                  _vm._v(" close ")
                ])
              ]
            )
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.termsShow,
                  expression: "termsShow"
                }
              ],
              staticClass: "m_body"
            },
            [_c("UseTerms")],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.privacyShow,
                  expression: "privacyShow"
                }
              ],
              staticClass: "m_body"
            },
            [_c("TermsPrivacy")],
            1
          ),
          _c("div", { staticClass: "m_footer" }, [
            _c(
              "button",
              {
                staticClass: "btn",
                on: {
                  click: function($event) {
                    return _vm.closeModal()
                  }
                }
              },
              [_vm._v("확인")]
            )
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "/" } }, [
      _c("img", {
        staticClass: "bgLogo",
        attrs: {
          src: require("@/assets/images/reviewmind/logo2.png"),
          alt: "logo"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }